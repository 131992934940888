<template>
  <div class="container">
    <el-row class="row shadow">
      <el-col :span="15"> <TimeZone @change="dataChange" @changeDateZone="selectDateZone"></TimeZone></el-col>
    </el-row>
    <el-row type="flex" justify="space-between">
      <el-col :span="8" class="col line shadow">
        <Chart
          chartId="first_line"
          :option="
            new LineOption({ title: `One-Way IP Amount`, keepPoint: 0 }).getOption([
              'title',
              'tooltip',
              'xAxis',
              'yAxis',
              'grid',
              'series',
            ])
          "
          :series="line_count"
        ></Chart>
      </el-col>
      <el-col :span="8" class="col line shadow">
        <Chart
          chartId="second_line"
          :option="
            new LineOption({ title: `One-Way Traffic (Kbps)`, yunit: 'Kbps' }).getOption([
              'title',
              'tooltip',
              'xAxis',
              'yAxis',
              'grid',
              'series',
            ])
          "
          :series="line_bps"
        ></Chart>
      </el-col>
      <el-col :span="8" class="col line shadow">
        <Chart
          chartId="third_line"
          :option="
            new LineOption({ title: `One-Way Traffic (pps)`, yunit: 'pps' }).getOption([
              'title',
              'tooltip',
              'xAxis',
              'yAxis',
              'grid',
              'series',
            ])
          "
          :series="line_pps"
        ></Chart>
      </el-col>
    </el-row>
    <!-- <el-row class="row" style="margin-bottom: 0px">
      <el-col :span="2">
        <div class="history-data">Top:{{ handleNumByDot(table.length) }}</div>
      </el-col>
      <el-col :span="4">
        <div class="history-data">Amount bps:{{ handleNumByDot(historyData.bps.toFixed(2)) }}</div>
      </el-col>
      <el-col :span="4">
        <div class="history-data">Amount pps:{{ handleNumByDot(historyData.pps.toFixed(2)) }}</div>
      </el-col>
    </el-row> -->
    <div class="table-box shadow">
      <PaginationTable
        :loading="loading"
        :originData="table"
        :showRule="showRule"
        :rowClick="rowClick"
        :defaultOrder="{ prop: 'byte', order: 'descending' }"
      ></PaginationTable>
    </div>
  </div>
  <!-- :routeQuery="{ date: dateArrayJSON }" -->
</template>

<script>
import DynamicRoute from '@/components/DynamicRoute'
import TimeZone from '@/components/TimeZone'
// import PaginationTable from '@/components/PaginationTable'
// import Chart from '@/submodules/components/Chart'
// import { getAppList } from '@/api/AppApi.js'
import NumberCol from '@/components/NumberCol'
import { getOneWayFlow, getOneWayFlowTableData } from '@/api/FlowApi'
import { AnomalyLineOption } from '@/templates/chart/lineTemplate'
export default {
  name: 'Anomaly',
  created() {
    this.ipVersion = localStorage.getItem('ip_version') || 'nf'
    // this.getAppList()
  },
  components: {
    TimeZone,
    // PaginationTable,
    // Chart,
  },
  data() {
    return {
      loading: false,
      LineOption: AnomalyLineOption,
      line_count: [],
      // dateArrayJSON: [],
      line_bps: [],
      line_pps: [],
      DataZone: parseInt(new Date().getTimezoneOffset() / 60),
      showRule: [
        {
          prop: 'Src_IP',
          label: 'IP',
          subComponent: DynamicRoute,
          rowFormat: row => {
            return [`/client_view/${row.Src_IP}`, row.Src_IP]
          },
        },
        {
          prop: 'App_Protocol',
          label: 'App',
          subComponent: DynamicRoute,
          rowFormat: row => {
            return [`/app_view/${row.App_Protocol}`, row.App_Protocol]
          },
        },
        // 隐藏protocol
        // { prop: 'Master_Protocol', label: 'Protocol' },
        {
          prop: 'count',
          label: 'Flow Amount',
          sortable: 'custom',
          width: '140px',
          compare: (a, b) => a.count - b.count,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.count
          },
        },
        {
          prop: 'packet',
          label: 'Packet',
          sortable: 'custom',
          compare: (a, b) => a.packet - b.packet,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.packet
          },
        },
        {
          prop: 'byte',
          label: 'Byte',
          sortable: 'custom',
          compare: (a, b) => a.byte - b.byte,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.byte
          },
        },
        {
          prop: 'Src_to_Dst_Packets',
          label: 'Client to Server Packets',
          sortable: 'custom',
          width: '130px',
          // fixed: 'right',
          compare: (a, b) => a.Src_to_Dst_Packets - b.Src_to_Dst_Packets,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Src_to_Dst_Packets
          },
        },
        {
          prop: 'Src_to_Dst_Bytes',
          label: 'Client to Server Bytes',
          sortable: 'custom',
          width: '130px',
          // fixed: 'right',
          compare: (a, b) => a.Src_to_Dst_Bytes - b.Src_to_Dst_Bytes,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Src_to_Dst_Bytes
          },
        },
        {
          prop: 'Dst_to_Src_Packets',
          label: 'Server to Client Packets',
          sortable: 'custom',
          width: '130px',
          // fixed: 'right',
          compare: (a, b) => a.Dst_to_Src_Packets - b.Dst_to_Src_Packets,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Dst_to_Src_Packets
          },
        },
        {
          prop: 'Dst_to_Src_Bytes',
          label: 'Server to Client Bytes',
          sortable: 'custom',
          width: '130px',
          // fixed: 'right',
          compare: (a, b) => a.Dst_to_Src_Bytes - b.Dst_to_Src_Bytes,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Dst_to_Src_Bytes
          },
        },
      ],
      // appOption: [],
      // appName: '',
      table: [],
      // historyData: {
      //   bps: 0,
      //   pps: 0,
      // },
      one_way_data: {},
      one_way_table_data: {},
    }
  },
  props: {},
  methods: {
    handleNumByDot(num) {
      if (!isNaN(Number(num))) {
        const str = new String(num).toString()
        const numberArray = str.split('.')
        const floatNum = numberArray[1] ? '.' + numberArray[1] : ''
        var LongNum = numberArray[0]
        for (var i = 3; i < LongNum.length; i += 4) {
          LongNum = LongNum.slice(0, LongNum.length - i) + ',' + LongNum.slice(LongNum.length - i)
        }
        return LongNum + floatNum
      }
    },
    async getLineData() {
      this.loading = true
      this.one_way_data = await getOneWayFlow(this.ipVersion, ...this.dateArray)

      // this.historyData.bps = 0
      this.handleMintueLine('line_count', 'Count')
      this.handleMintueLine('line_bps', 'bps')
      this.handleMintueLine('line_pps', 'pps')
      // console.debug(this.line_bps)

      this.loading = false
    },

    async getTableList() {
      this.loading = true
      this.one_way_table_data = await getOneWayFlowTableData(this.ipVersion, ...this.dateArray)
      const passTime = (this.dateArray[1] - this.dateArray[0]) / 1000
      this.table = this.one_way_table_data['flow_table'].map(item => {
        item.App_Protocol = item._id.App_Protocol
        item.Master_Protocol = item._id.Master_Protocol
        item.Src_IP = item._id.Src_IP
        item.byte = item.sum_byte
        item.packet = item.sum_packet
        item.bps = ((item.byte * 8) / passTime).toFixed(2)
        // this.historyData.bps += parseFloat(item.bps)
        item.pps = (item.packet / passTime).toFixed(4)
        // this.historyData.pps += parseFloat(item.pps)
        return item
      })
      this.loading = false
    },

    handleMintueLine(listName, seriesName) {
      // console.debug(this.one_way_data[listName])
      this[listName] = this.one_way_data[listName].map(item => {
        item.data = item.data.map(this.transformDateWithTimeZone)
        item.showSymbol = item.data.length < 10 ? true : false
        item.type = 'line'
        return item
      })
    },
    transformDateWithTimeZone(lineitem) {
      lineitem[0] = lineitem[0] - this.DataZone * 60 * 60 * 1000
      return lineitem
    },
    rowClick(row, column, event) {
      if (event.srcElement.nodeName === 'A') {
        return
      }
      this.$router.push({
        path: `/flow_view/`,
        query: {
          // date: this.dateArrayJSON,
          appName: row.App_Protocol,
          ip: row.Src_IP,
          protocolType: 'App',
        },
      })
    },
    async dataChange(dateArray, DataZone) {
      this.dateArray = dateArray
      this.DataZone = DataZone
      // this.dateArrayJSON = [
      //   new Date(parseInt(dateArray[0]) - this.DataZone * 60 * 60 * 1000).toJSON(),
      //   new Date(parseInt(dateArray[1]) - this.DataZone * 60 * 60 * 1000).toJSON(),
      // ]
      this.getLineData()
      this.getTableList()
    },
    selectDateZone(newDateZone, dateArray) {
      this.DataZone = newDateZone
      this.dateArray = dateArray
      this.dateArrayJSON = [
        new Date(parseInt(dateArray[0]) - this.DataZone * 60 * 60 * 1000).toJSON(),
        new Date(parseInt(dateArray[1]) - this.DataZone * 60 * 60 * 1000).toJSON(),
      ]
      this.getLineData()
      this.getTableList()
    },
    // appChange(appIndex) {
    //   this.appName = this.appOption[appIndex].label
    //   this.getTablelist()
    // },
  },
}
</script>

<style lang="scss" scoped>
.selector {
  padding-right: 20px;
  width: calc(100% - 20px);
}
.history-data {
  display: flex;
  align-items: center;
  height: 100%;
}
.col {
  background-color: #fff;
}

.line {
  width: 33%;
  height: 30vh;
  padding: 5px;
}
</style>
